import { Flex, Link, Picto } from '@stars-ecom/shared-atoms-ui'
import { AccountCreate, WebsiteContext } from '@stars-ecom/shared-organisms-ui'
import React, { useContext } from 'react'
import { Helmet } from 'react-helmet'

import { siteFormatter } from '../utils/metaTitleFormatter'

const CreateAccountPage = (props) => {
  const { pageContext } = props
  const { isCheckout = false } = pageContext
  const websiteContext = useContext(WebsiteContext)

  return (
    <Flex direction="column" w="100%" pb="20px">
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content={`Créer un compte - ${siteFormatter(process.env.GATSBY_API_WEBSITE)}`}
        />
        <title>{`Créer un compte - ${siteFormatter(process.env.GATSBY_API_WEBSITE)}`}</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Flex
        direction="column"
        width={{ base: '100%', md: '1000px' }}
        maxWidth={{ base: '420px', md: '1000px' }}
        paddingLeft={{ base: '10px', md: '15px' }}
        paddingRight={{ base: '10px', md: '15px' }}
        justify="space-between"
        align="flex-start"
        alignSelf="center"
        marginTop="10px">
        <Flex
          direction="row"
          align="center"
          fontFamily="PT Sans, arial, sans-serif"
          fontSize="14px"
          mb={{ base: '10px', md: '20px' }}>
          <Picto icon="chevronLeft" width="11px" height="11px" color={websiteContext?.mainColor} />
          <Link
            to="/checkout/onepage"
            style={{ textDecoration: 'underline', marginLeft: '5px', cursor: 'pointer' }}>
            Retour au panier
          </Link>
        </Flex>
        <AccountCreate isCheckout={isCheckout} />
      </Flex>
    </Flex>
  )
}

CreateAccountPage.pageType = 'createAccount'

export default CreateAccountPage
